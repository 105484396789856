<template>
  <v-card>
    <v-card-title class="text-h6"
      >{{ $t("labels.duplicate_special_code") }}
    </v-card-title>
    <v-card-text>{{ $t("labels.duplicate_special_code_list") }} </v-card-text>

    <v-card-text>
      <v-simple-table>
        <template v-slot:default>
          <thead class="v-data-table-header">
            <tr>
              <th role="columnheader" class="text-center">
                {{ $t("labels.sku") }}
              </th>
              <th role="columnheader" class="text-center">
                {{ $t("labels.barcode") }}
              </th>
              <th role="columnheader" class="text-center">
                {{ $t("labels.image") }}
              </th>
              <th role="columnheader" class="text-center">
                {{ $t("labels.goods") }}
              </th>
              <th role="columnheader" class="text-center">
                {{ $t("labels.goods_description") }}
              </th>
              <th role="columnheader" class="text-center">
                {{ $t("labels.size") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, k) in listGoods"
              :key="`checking_${k}_${item.id_goods}`"
              class="text-center"
            >
              <td>
                {{ item.sku }}
                <template v-if="item.uid">
                  <br />
                  <span class="error--text">{{ item.uid }}</span>
                </template>
              </td>
              <td>
                {{ item.customer_goods_barcode }}
                <template v-if="item.barcode">
                  <br />
                  <span class="error--text">{{ item.barcode }}</span>
                </template>
              </td>
              <td>
                <ImageViewer v-if="item.url_images" :url="item.url_images" />
              </td>
              <td>{{ item.goods_name }}</td>
              <td>{{ item.description }}</td>
              <td>{{ item.size }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>

    <v-card-text>
      <v-text-field
        v-model="code"
        class="c-input-xs"
        @keyup.enter="scanSku"
        :label="$t('labels.qr_sku_sub_barcode')"
        :placeholder="$t('labels.qr_sku_sub_barcode')"
        autofocus
        dense
        single-line
        outlined
        clearable
        hide-details
      ></v-text-field>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="red darken-1" text @click="cancel">
        {{ $t("labels.cancel") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "PackingSelectSku",
  components: {
    ImageViewer: () => import("@/components/goods/ImageViewer"),
  },
  props: {
    listGoods: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    code: null,
  }),
  methods: {
    cancel() {
      this.$emit("cancel");
    },

    getGoodsCodes(item) {
      const goodsCodes = [`${item.sku}`];
      if (item.customer_goods_barcode) {
        goodsCodes.push(item.customer_goods_barcode.toUpperCase());
      }
      if (item.sub_barcode) {
        goodsCodes.push(item.sub_barcode.toUpperCase());
      }
      return goodsCodes;
    },

    scanSku() {
      const item = [...this.listGoods].find((i) =>
        this.getGoodsCodes(i).includes(this.code.toUpperCase())
      );
      if (!item || !item.id_goods) {
        this.$vToastify.error(this.$t("messages.goods_not_found"));
        document.getElementById("error_sound_player").play();
        return false;
      }
      this.$emit("confirm", { sku: `${item.sku}`, id: item.id });
    },
  },
};
</script>

<style scoped></style>
